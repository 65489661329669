import { render, staticRenderFns } from "./GuestBox.vue?vue&type=template&id=67977f50&scoped=true&"
import script from "./GuestBox.vue?vue&type=script&lang=js&"
export * from "./GuestBox.vue?vue&type=script&lang=js&"
import style0 from "./GuestBox.vue?vue&type=style&index=0&id=67977f50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67977f50",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67977f50')) {
      api.createRecord('67977f50', component.options)
    } else {
      api.reload('67977f50', component.options)
    }
    module.hot.accept("./GuestBox.vue?vue&type=template&id=67977f50&scoped=true&", function () {
      api.rerender('67977f50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-89VnH74yy28iw/JS/comp/GuestBox.vue"
export default component.exports